<template>
  <ion-modal
    class="modal-small"
    :is-open="isOpen"
    mode="md"
    :backdrop-dismiss="true"
    :show-backdrop="true"
    @didDismiss="dismissModal"
  >
    <modals-layout :data="{ title: modalTitle }" @dismiss-modal="dismissModal">
      <div class="d-flex flex-column justify-content-center align-items-center h-100">
        <div class="message">Are you sure you want to remove this section?</div>
        <div class="actions d-flex mt-3">
          <ion-button color="danger" @click="remove"> Remove </ion-button>

          <ion-button color="medium" @click="dismissModal"> Cancel </ion-button>
        </div>
      </div>
    </modals-layout>
  </ion-modal>
</template>

<script lang="ts">
import { Options, Vue } from 'vue-class-component';
import { Emit, Prop } from 'vue-property-decorator';


@Options({
  name: 'DeleteSectionConfirmationModal',
  
})
export default class DeleteSectionConfirmationModal extends Vue {
  @Prop({ default: () => '', required: false }) modalTitle!: string;
  @Prop({ default: false }) isOpen!: boolean;

  @Emit('dismissModal')
  public dismissModal() {
    
  }

  @Emit('dismissModal')
  @Emit('remove')
  public remove() {
    
  }
}
</script>

<style lang="sass" scoped>
.modal-small
  --height: 200px !important
</style>
